module.exports = [{
      plugin: require('/Users/samsonyuwono/Desktop/Dummy/portfolio-website-mate/mate-portfolio/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Open Sans"]}},
    },{
      plugin: require('/Users/samsonyuwono/Desktop/Dummy/portfolio-website-mate/mate-portfolio/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mate Gatsby Starter","short_name":"Mate Gatsby Starter","start_url":"/","background_color":"#FFFFFF","theme_color":"#7c37ad","display":"minimal-ui","icon":"media/icon.png"},
    },{
      plugin: require('/Users/samsonyuwono/Desktop/Dummy/portfolio-website-mate/mate-portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
